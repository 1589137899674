import { useCallback, useEffect, useState,useRef } from "react"
import FeatherIcon from 'feather-icons-react'
import NoContent from "./pageComp/NoContent";
export default function Archives(p){
  let lix=[],i=0,fI=0,ix=0,fIndex,lix2=[];
  let today=new Date(),year=today.getFullYear(),month=today.getMonth();
  const [kinyB,setKiny]=useState([]);
  //<img className='KinyImg iMgTop' src={kinya.cover}/>
  const showKn=(array,ty)=>{
    const showKnss=(kiny)=>{
      var subS=localStorage.getItem('_kiny_');
    subS= (subS == null) ? 1 : JSON.parse(subS);
    subS= (subS==1) ? subS : subS.sub.length==0 ? 1 :subS.sub[0].plan;
    if(subS>=kiny.plan){p.showKiny(true);p.kinyPdf(kiny)}
    else{p.setNewsP(kiny.plan);p.subDv()}
    }
   var newA=array.map((kinya,i) => {
      return <div onClick={e=>{showKnss(kinya)}} className='kinyOld_sLgf'>
      <div className='KinyTop'>
    {ty==1 && <img className='KinyImg iMgTop kinyCC' data-src={kinya.cover}/>}
       </div>
     <div className='KinyBtM'>{kinya.bottom_title}</div>
      </div>
    });
  return newA;
  }
  const funcShowKiny=(year)=>{
    setKiny(showKn([{},{},{},{},{},{}],0));
    fetch(p._lk+"kinyamatekalistdate/"+year,{
      method:'GET',headers:{'Content-Type': 'application/json'}
      }).then(res=>res.json()).then(ty=>{
  if(ty.length==0){var nmsx=<NoContent value='Nta Kinyamateka zirajyamo'/>;setKiny(nmsx);}
  else{var nmsx=showKn(ty,1);setKiny(nmsx);}
      });
  }

   for (let index =year;index>=1930;index--) {
      let _break=index+'';_break=_break.charAt(_break.length-1);
       let nx=(ix==0 && fI==0) ? ' _dfRTx3':'';
      lix2[fI]=<li onClick={e=>{funcShowKiny(index)}} className={"_liC34"+nx}>{index}</li>;
      if(fI==0){fIndex=index;}fI++;
      if(_break==0){
        let adC= ix==0 ? ' ' : ' HdX',ncd=ix==0 ? ' xdDsfr' : ''; 
        lix[i]=<li className={"_liXf4"+ncd}><p className="XpXs">{fIndex+"-"+index}</p><ul className={"ulXcdS"+adC}>{lix2}</ul></li>;
        fI=0;lix2=[];
        ix++;
      }
    i++;  
   }
   useEffect(() => {
    let date=new Date(),yearX=date.getFullYear();funcShowKiny(yearX);
  let urlX=document.querySelectorAll('.XpXs'),
  _tyV=document.querySelectorAll('.ulXcdS'),liss=document.querySelectorAll('._liC34');
  Array.from(urlX).forEach(a=>{
    a.addEventListener('click',(e)=>{
     var sibling=a.nextElementSibling,paRt=a.parentElement;
  Array.from(_tyV).forEach(b=>{b.classList.add('HdX');
   b.parentElement.classList.remove('xdDsfr');})
  sibling.classList.toggle('HdX');paRt.classList.add('xdDsfr');
    })
  })

  Array.from(liss).forEach(bn=>{
    bn.addEventListener('click',(e)=>{
      Array.from(liss).forEach(an=>{an.classList.remove('_dfRTx3')})
      bn.classList.add('_dfRTx3');

    });
  });


   }, [])

   useEffect(()=>{
    var elem=document.querySelectorAll('.kinyCC');
   Array.from(elem).forEach(async(ny)=>{
     ny.classList.add('hidden');ny.src=ny.dataset.src;
     ny.onload=()=>{ny.classList.remove('hidden');}
    })
    })
   
    return (
   <div className="div_bNyx _archivesss">
      <div className="_archiveL">{kinyB}</div>
      <div className="_archiveR">
      <ul className="archiveLI">
          <li className="li_Ttl">Hitamo Umwaka</li>
        {lix}
      </ul>

      </div>
   </div>
    );

}