import { useCallback, useEffect, useState, useRef } from "react";
import FeatherIcon from "feather-icons-react";
export default function Audio(p) {
  const audios = p.ty,
    [audioV, setAudios] = useState([]),
    [orAudio, setOgAudio] = useState([]),
    [audioLinks, setAudioLinks] = useState([{}]),
    [timeUpdate, setTime] = useState("00:00 / 00:00"),
    [currentAudio, setCurrentAudio] = useState("");
  var lis = audios.submenu_menu.map((ty, i) => (
    <li
      key={i}
      onClick={(e) => {
        fetchAudio(ty.id);
      }}
    >
      {ty.title}
    </li>
  ));
  const functionShowAudio = (ty, current = {}) => {
    let txY = [],
      auL = {};
    if (ty.length > 0) {
      ty.forEach((au, i) => {
        var classNn = current.file == au.file ? "currentAu" : "";
        txY[i] = (
          <li
            className={"nKJdsf " + classNn}
            key={i}
            onClick={(e) => {
              setCurrentAudio(au);
              functionShowAudio(orAudio, au);
            }}
            data-src={au.file}
          >
            <span className="xsDsec">
              <FeatherIcon height="13" width="13" icon="activity" />
            </span>
            {au.description}
          </li>
        );
      });
    } else {
      txY[0] = <li>Nta audio zihari</li>;
    }
    setAudios(txY);
  };
  const fetchAudio = (ty) => {
    setAudios([]);
    let link = ty == "all" ? "audio-play-list/" : "audio-list/" + ty + "/";
    fetch("https://kinyamateka.rw:80/" + link, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((ty) => {
        var curr = ty.length == 0 ? {} : ty[0];
        functionShowAudio(ty, curr);
        setAudioLinks(ty);
        setCurrentAudio(ty.length == 0 ? {} : ty[0]);
        setOgAudio(ty);
      });
  };
  useEffect(() => {
    fetchAudio("all");
    var CFfrt = document.querySelector(".p-hiCdsd");
    var AudX = document.querySelector(".AudX");
    const fuc = () => {
      AudX.classList.add("HdX");
    };
    CFfrt.addEventListener("click", fuc);
    return () => {
      CFfrt.removeEventListener("click", fuc);
    };
  }, []);
  //play Audio
  const playAudio = (aud) => {};
  //audio playlists

  const [ppI, setPicn] = useState("pause");
  useEffect(() => {
    function formatTime(val) {
      var h = 0,
        m = 0,
        s;
      val = parseInt(val, 10);
      if (val > 60 * 60) {
        h = parseInt(val / (60 * 60), 10);
        val -= h * 60 * 60;
      }
      if (val > 60) {
        m = parseInt(val / 60, 10);
        val -= m * 60;
      }
      s = val;
      val = h > 0 ? h + ":" : "";
      val += m > 0 ? (m < 10 && h > 0 ? "0" : "") + m + ":" : "0:";
      val += (s < 10 ? "0" : "") + s;
      return val;
    }
    const audioElement = document.querySelector("#audioXc"),
      elm2 = document.querySelector("#audioXc"),
      playpause = document.querySelector(".playPause"),
      rangeTime = document.getElementById("onrangfe"),
      prevAud = document.querySelector(".prevSongW"),
      nextAud = document.querySelector(".nextSongXX");

    audioElement.autoplay = true;
    rangeTime.value = 0;
    const playList = (reverse = false) => {
        var currenta = audioElement.dataset.src;
        var audioIndex = audioLinks
          .map((au, i) => {
            if (au.file == currenta) {
              return { aud: au, ix: i };
            }
          })
          .filter((ax) => typeof ax !== "undefined");
        if (audioIndex.length > 0) {
          var nextaudioI =
            reverse == true ? audioIndex[0].ix - 1 : audioIndex[0].ix + 1;

          var nextAudio =
            reverse == true
              ? nextaudioI >= 0
                ? audioLinks[nextaudioI]
                : audioLinks[audioLinks.length - 1]
              : audioLinks.length > nextaudioI
              ? audioLinks[nextaudioI]
              : audioLinks[0];
          setCurrentAudio(nextAudio);
          functionShowAudio(orAudio, nextAudio);
        }
      },
      playPause = () => {
        if (audioElement.paused) {
          audioElement.play();
          setPicn("pause");
        } else {
          audioElement.pause();
          setPicn("play");
        }
      },
      updateTime = () => {
        var currentTime = audioElement.currentTime,
          totalDuration = audioElement.duration;
        var timeUp = isNaN(totalDuration)
          ? "00:00 / 00:00"
          : formatTime(currentTime) + " / " + formatTime(totalDuration);
        setTime(timeUp);
        rangeTime.value = (currentTime / totalDuration) * 100;
      },
      playAudioTo = () => {
        if (audioElement.readyState == 4) {
          audioElement.currentTime =
            (rangeTime.value * audioElement.duration) / 100;
          audioElement.addEventListener("timeupdate", updateTime);
        }
      },
      prevAudio = () => {
        playList(true);
      },
      nextAudio = () => {
        playList(false);
      };
    elm2.addEventListener("ended", nextAudio);
    playpause.addEventListener("click", playPause);
    nextAud.addEventListener("click", nextAudio);
    prevAud.addEventListener("click", prevAudio);
    audioElement.addEventListener("timeupdate", updateTime);
    rangeTime.addEventListener("mouseup", playAudioTo);
    rangeTime.addEventListener("touchend", playAudioTo);

    /*
  const audioCtx=new (window.AudioContext || window.webkitAudioContext)();
  const canvasElement=document.querySelector('.canvasxc');
    const canvasCtx=canvasElement.getContext('2d');
     const width=canvasElement.clientWidth,height=canvasElement.clientHeight;

     const source=audioCtx.createMediaElementSource(audioElement);
     const analyzer=audioCtx.createAnalyser();
     analyzer.fftSize=256;
     source.connect(analyzer);
     analyzer.connect(audioCtx.destination);

     const bufferLength=analyzer.frequencyBinCount;
     const dataArray=new Uint8Array(bufferLength);
    
     function draw(){
        analyzer.getByteFrequencyData(dataArray);
        canvasCtx.fillStyle='rgb(2,2,2)';
        canvasCtx.fillRect(0,0,width,height);

        const barWidth=(width/bufferLength)*2.5;
        let barHeight;
        let x=0;
        for (let i=0; i<bufferLength;i++) {
            barHeight=dataArray[i]/2.8;
            canvasCtx.fillStyle='white';
            canvasCtx.fillRect(x,height-barHeight,barWidth,barHeight);
            x+=barWidth+1; 
        }
        requestAnimationFrame(draw);
     }
    draw();
     */

    return () => {
      elm2.removeEventListener("ended", nextAudio);
      playpause.removeEventListener("click", playPause);
      audioElement.removeEventListener("timeupdate", updateTime);
      rangeTime.removeEventListener("mouseup", playAudioTo);
      rangeTime.removeEventListener("touchend", playAudioTo);
      nextAud.removeEventListener("click", nextAudio);
      prevAud.removeEventListener("click", prevAudio);
    };
  }, [audioLinks]);
  return (
    <div className="AudX">
      <div className="overlayx"></div>
      <div className="AUdXio">
        <div className="audioH">
          <div className="AudH">
            <span className="pTtitle">
              <FeatherIcon height="18" width="18" icon="music" />
              <p>{audios.title}</p>
            </span>
            <span className="SpNaU">
              <p className="p-hiCdsd cursor-pointer scaleX">
                <FeatherIcon height="20" width="20" icon="minimize" />
              </p>
              <p className="pClcs" onClick={p.hideA}>
                <FeatherIcon height="20" width="20" icon="x" />
              </p>
            </span>
            <div className="audioPlayer">
              <audio
                data-src={currentAudio.file}
                src={currentAudio.file}
                id="audioXc"
              ></audio>
              <canvas className="canvasxc" />
              <div className="controlsWE">
                <div className="divMic">
                  <FeatherIcon icon="music" />
                </div>
                <div className="dv2x">
                  <p className="currentAuTi">
                    {typeof currentAudio.description !== "undefined" &&
                      currentAudio.description.slice(0, 70)}
                  </p>
                  <div className="divRange">
                    <span className="prevSongW nhDSs">
                      <FeatherIcon height="20" width="20" icon="skip-back" />
                    </span>
                    <span className="playPause nhDSs">
                      <FeatherIcon height="20" width="20" icon={ppI} />
                    </span>
                    <span className="nextSongXX nhDSs">
                      <FeatherIcon height="20" width="20" icon="skip-forward" />
                    </span>

                    <input className="inpyRange" id="onrangfe" type="range" />
                    <span className="DurationNow1 djjdk">{timeUpdate}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul className="ULaUDIO">
            <li
              onClick={(e) => {
                fetchAudio("all");
              }}
            >
              Zose
            </li>
            {lis}
          </ul>

          <div className="AudConta">
            <ul className="audio-list">{audioV}</ul>
          </div>
        </div>
      </div>
    </div>
  );
}
