import React,{ useState, useRef, useEffect} from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import FeatherIcon from 'feather-icons-react';
import pdf1 from '../../images/kmx.pdf';
export default function ShowKinyPdf(p){
    const kiny=p.kinyPdf,[page, setPage] = useState(1)
    useEffect(()=>{
        var canvas=document.querySelector('.convasClassName');
        var plus=document.querySelector('.cliCpl');
        var minusx=document.querySelector('.cliCpl2');
        
        function MinusplusCanvas(plus=true){
            var canvas_h=canvas.style.height,canvas_w=canvas.style.width,
            canvasW=canvas_w;canvas_h='auto';

            canvasW=(canvasW.indexOf('%')!=-1) ?
            canvasW.split('%').join('') : (canvasW.indexOf('px')!=-1) ?
            canvasW.split('px').join('') : canvasW;
           
            canvasW=canvasW>100 ? '50' : canvasW
          
           canvas_w= (plus===true) ? +canvasW+10 : +canvasW-10
           if(canvas_w>=100){canvas_w=100}
           if(canvas_w<=20){canvas_w=20}
          canvas.style.width=canvas_w.toString()+'%';
          canvas.style.height=canvas_h
        }
        const minus=()=>MinusplusCanvas(false);
        const plusC=()=>MinusplusCanvas(true);
        plus.addEventListener('click', plusC)
        minusx.addEventListener('click', minus)
        return ()=>{
            plus.removeEventListener('click',plusC)
            minusx.removeEventListener('click',minus)
        } 
    },[])
    
    const canvasRef = useRef(null),
          { pdfDocument, pdfPage } = usePdf({file:kiny.pdf,page,canvasRef});
    return <div className="showKinyaPdf">
        <div className="_kinyatop">
<div onClick={p.hideKinya} className='F-iconsX fx33'><FeatherIcon height='30' width='30' icon='x'/></div>
<div className='PTFdf'>
    <p className='p-title'>{kiny.bottom_title}</p>
    <div className='fCd flex flex-row'>
    <div className='cliCpl F-iconsX fx111'><FeatherIcon height='25' width='25' icon='plus'/></div>
    <div className='cliCpl2 F-iconsX fx222'><FeatherIcon height='25' width='25' icon='minus'/></div>
    </div>
</div>
        </div>
        <div className="_kinyaButton">
            <div className="_kinyPhotoContainer">
            <div className='DcVFR'>
      {!pdfDocument && <p className='pClodsd'>Loading...</p>}
      <canvas className='convasClassName' ref={canvasRef} />
    </div>
            </div>
            {Boolean(pdfDocument && pdfDocument.numPages) && 
            <div className="_kinyPrevNext">
            <div disabled={page === 1} onClick={() => setPage(page - 1)} className='F-iconsX fx11'><FeatherIcon height='30' width='30' icon='chevron-left'/></div>
    <div disabled={page === pdfDocument.numPages} onClick={() => setPage(page + 1)} className='F-iconsX fx22'><FeatherIcon height='30' width='30' icon='chevron-right'/></div>
            </div>
            }    
        </div>

    </div>
}