import FeatherIcon from "feather-icons-react";
import { useEffect, useState } from "react";
export default function HomeNavTop(p) {
  const [showAc, isShowAc] = useState(false),
    [AcData, setAcData] = useState({});
  const [shCC, isShowCC] = useState("");
  const convertDate = (date) => {
    var datex = new Date(date).getTime(),
      todayDate = new Date().getTime(),
      tDf = datex - todayDate,
      tM = "Second";
    if (tDf < 0) {
      //localStorage.getItem('_kiny_');
    } else {
      let dateM = tDf / 1000;
      if (dateM >= 60 && tM == "Second") {
        dateM = dateM / 60;
        tM = "Minute";
      }
      if (dateM >= 60 && tM == "Minute") {
        dateM = dateM / 60;
        tM = "Hour";
      }
      if (dateM >= 24 && tM == "Hour") {
        dateM = dateM / 24;
        tM = "Day";
      }
      if (dateM >= 30 && tM == "Day") {
        dateM = dateM / (365.5 / 12);
        tM = "Month";
      }
      if (dateM >= 12 && tM == "Month") {
        dateM = dateM / 12;
        tM = "Year";
      }
      dateM = Math.round(dateM);
      return dateM > 1
        ? dateM + " " + tM + "s" + " Left"
        : dateM + " " + tM + " Left";
    }
  };

  const Logout = () => {
    localStorage.removeItem("_kiny_");
    p.showAccount("lgO");
  };
  useEffect(() => {
    let _ntypes = document.querySelector(".navLeft"),
      _Nsprt = document.querySelector(".AccImages"),
      _chgCls = (ty) => _ntypes.classList.toggle("_subsActive"),
      _chng23 = (e) => {
        if (!_ntypes.contains(e.target)) {
          _ntypes.classList.remove("_subsActive");
        }
      };
    if (_Nsprt !== null) {
      _Nsprt.addEventListener("click", _chgCls);
    }
    document.addEventListener("click", _chng23);
    return () => {
      if (_Nsprt !== null) {
        _Nsprt.removeEventListener("click", _chgCls);
      }
      document.removeEventListener("click", _chng23);
    };
  }, [showAc]);

  useEffect(() => {
    var datas = localStorage.getItem("_kiny_");
    if (datas !== null) {
      datas = JSON.parse(datas);
      fetch(
        "https://kinyamateka.rw:80/check-subscription/" + datas.user.user_id + "/",
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => res.json())
        .then((sub) => {
          datas.sub = sub;
          localStorage.setItem("_kiny_", JSON.stringify(datas));
        });
      if (datas.sub.length == 0) {
        setTimeout(() => {
          p.subDv();
        }, 3000);
      }

      isShowAc(true);
      setAcData(datas);
    } else {
      setTimeout(() => {
        p.subDv();
      }, 3000);
      isShowAc(false);
    }
  }, [p.Account]);
  //<span className='Fmenu_ hidden'><FeatherIcon  icon='menu'/></span>
  //<li className='hidden'>Guhindura amakuru</li>
  return (
    <div className="navTop">
      <div className="nav-right">
        <span className="_spNtuma">Tuma Kinyamateka </span>
        <span>+250 783 462 125</span>
        <span>ikinyamateka@gmail.com</span>
      </div>
      <div className="navLeft">
        {showAc == true ? (
          <div className="subscribedU">
            <div className="AccImages">
              <p className="userAccountName">
                {AcData.user.first_name + " " + AcData.user.last_name}
              </p>
              <p className="_ptrXo">
                <FeatherIcon icon="user" height="25" width="25" />
              </p>
              {AcData.sub.length > 0 ? (
                <p className="subTYpes red">
                  {AcData.sub[0].plan == 2
                    ? "Basic"
                    : AcData.sub[0].plan == 3
                    ? "Premium"
                    : ""}
                </p>
              ) : (
                ""
              )}
            </div>

            <ul className={"ulSubs " + shCC}>
              <li className="_tiDkTs">
                <p className="pActT">
                  {AcData.user.first_name + " " + AcData.user.last_name}
                </p>
                {AcData.user.email != "" && <p>{AcData.user.email}</p>}
                {AcData.user.email == "" && AcData.user.phone != "" && (
                  <p>{AcData.user.phone}</p>
                )}
                <p>{AcData.user.address + " " + AcData.user.parish}</p>
                {AcData.sub.length > 0 && (
                  <p className="_ttyrC32">
                    {convertDate(AcData.sub[0].end_date)}
                  </p>
                )}
              </li>
              <li onClick={p.showSubs}>Guhindura Ifatabuguzi</li>
              <li>Raporo y'Ifatabuguzi</li>
              <li
                onClick={(e) => {
                  p.lgn();
                  isShowCC("HdN");
                }}
              >
                Injira muyindi konti
              </li>
              <li
                onClick={(e) => {
                  p.signUp();
                  isShowCC("HdN");
                }}
              >
                Gukora konti nshya
              </li>

              <li
                onClick={(e) => {
                  Logout();
                }}
              >
                Gusohoka
              </li>
            </ul>
          </div>
        ) : (
          <div className="pNotLogedIn">
            <span onClick={p.lgn} className="spnS spNL">
              <FeatherIcon height="16" width="16" icon="log-in" />{" "}
              <span className="sxP">
                <a>Injira</a>
              </span>
            </span>
            <span onClick={p.signUp} className="spnS spnNM">
              <FeatherIcon height="16" width="16" icon="thumbs-up" />
              <span className="sxP">
                <a>Kwiyandikisha</a>
              </span>
            </span>
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
}
