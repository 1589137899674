import { useEffect, useState } from "react";



export default function ChooseSubs(p) {
  const newsP = typeof p.newsP === "undefined" ? 1 : p.newsP;
  const [Plan, setPlan] = useState([]);
  const [showEr2, isShowEr2] = useState(false);
  const [showTrue, isShowTrue] = useState(false);


  const savePlan = (pln_id) => {
      const _savePlanData = { plan: pln_id };
      var datas = localStorage.getItem("_kiny_");
      if (datas !== null) {
        datas = JSON.parse(datas);
        _savePlanData.subscriber_id = datas.user.user_id;
        fetch("http://www.kinyamateka.rw:443/operations/subscription/", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(_savePlanData),
        })
          .then((res) => res.json())
          .then((ty) => {
            if (typeof ty.id !== "undefined") {
              localStorage.setItem("_PendiNG+");
            }
          });
      } else {
        isShowEr2(true);
      }
    },


    showPlan = (ty) => {
      var subS = localStorage.getItem("_kiny_");
      subS = subS == null ? 1 : JSON.parse(subS);
      subS = subS == 1 ? subS : subS.sub.length == 0 ? 1 : subS.sub[0].plan;

      var planx = ty.map((dt, i) => {
        let condition =
          Object.keys(dt).length == 0
            ? dt.id != 4 && dt.id != 1
            : dt.id != 4 && dt.id > subS && dt.id >= newsP;


        if (condition) {
          return (
            <div key={i} className="SubCard h-auto">
              <p className="subPlx">{dt.title}</p>
              <p className="XPrice">
                RWF {dt.price}
                <sub className="SubXPr">/Umwaka</sub>
              </p>
              {dt.id > 1 && (
                <span
                  className="sPbbUY"
                  onClick={(e) => {
                    savePlan(dt.id);
                  }}
                >
                  <button className="bg-primary text-white">Ishyura</button>
                </span>
                
              )}

              <ul className="DscXwQ"></ul>
            </div>
          );
        }
      });
      return planx;
    };

  useEffect(() => {
    setPlan(showPlan([{}, {}, {}]));
    fetch("https://kinyamateka.rw:80/operations/plan/", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((ty) => {
        setPlan(showPlan(ty));
      });
  }, []);

  return (
    <div className="subSCR">
      <div className="SbPlN">
        {p.ht !== true && <h1 className="Phdeade">Hitamo Ifatabuguzi</h1>}
        
        {showEr2 && (
          <p className="lnSchy">
            Banza ukore konti nshya cyangwa winjire muyawe niba uyifite
          </p>
        )}

        {showTrue && (
          <p className="bnjBgt">
            Wakoze gufata Ifatabuguzi. Ifatabuguzi ryawe ryoherejwe. Kurikiza
            Amabwiriza yo kwishyura utegereze ko wemererwa
          </p>
        )}

        <div className="sUBcARD">{Plan}</div>
      </div>
    </div>
  );
}
