import { useState } from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import AdM from "./comps/admin/AdM";
import NotFound from "./comps/public/NotFound";
import Public from "./comps/public/Public";
import SubAccount from "./comps/subacc/SubAccount";
//<Public/>  <SubAccount/>  <AdNav/>
function App() {
  const [aLg,SAd]=useState(false),[suBLg,Ssb]=useState(false),
  LInkX='https://kinyamateka.rw:80/';
  //LInkX='https://www.kinyamateka.rw:80/'
  return (
    <HashRouter>
    <div className="App">
      <Routes>
      <Route path="/*" element={<Public _lk={LInkX}/>}></Route>
      <Route path="/a/*" element={ <AdM/>}></Route>
      <Route path="/s/*" element={ <SubAccount/>}></Route>
      </Routes>
    </div>
    </HashRouter>
    
  );
}

export default App;
/*

 <div className="news-dv">
       <div></div>
       <div className="GrdX TpNlFt">
       <div className="colM1 TpNlXpYCu">
           <div className="topTVCF-Slider">
   <InfoCard content='Amakuru Agezweho'/>
   <div className='Innerw'>
     <img className='iMgTop'  src={books} ></img>
     </div>
             
           </div>
           <div className="btmYVdg-t">
               <AudioButton/>
            <p className="spNNTtl">{ptext}</p>
            <div className='ActinDVX'>
        <span className='ContaiSpN'>
        <span className='Nvyx'>1,245</span>
        <span className='NbvyX'>
    <FeatherIcon height='16' width='16' icon='message-circle'/>
        </span>
        </span>

        <span className='ContaiSpN'>
        <span className='Nvyx BVGHx'> 
        <FeatherIcon height='12' width='12' icon='heart'/><p>Like </p></span>
        <span className='NbvyX Nwht'> 1,245</span>
        </span>

<span className='SocXl scxl fcBk'><FeatherIcon height='14' width='14' icon='facebook'/></span>
<span className='SocXl tWITTE'><FeatherIcon height='14' width='14' icon='twitter'/></span>
<span className='SocXl whatps'><WhattappIcon height='14px' width='14px' fill='white' /></span>

                     
             </div>
           </div>
       </div>


       <div className="colM2 TpNlXpYKiny">

        <div className='KinyTop'>
        <InfoCard content='Kinyamateka Nshya 02/2022'/>
        </div>
       <div className='KinyBtM'></div>

       </div>
       </div>
       
     
       <OtherNews/>
       <NewsSocial/>



       </div>
       

*/








  // "browserslist": {
  //   "production": [
  //     ">0.2%",
  //     "not dead",
  //     "not op_mini all"
  //   ],
  //   "development": [
  //     "last 1 chrome version",
  //     "last 1 firefox version",
  //     "last 1 safari version"
  //   ]
  // },